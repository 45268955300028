<template>
    <div class="defaultBox">
        <div class="myBoxHeader">
            <van-icon class="myBoxHeaderTitleIcon" @click="goHome" size='0.7rem' name="arrow-left" />

            <div class="myBoxHeaderTitle">检验报告详情</div>
            <p class="myBoxHeaderTitleName">{{this.$store.state.tagName.paritemName || '常规检查'}}</p>
            <div class="myBoxHeaderTable">
                <div class="myBoxHeaderTableLeft">
                    <p>姓名: {{userinfoData.patient.cName}}</p>
                    <!-- <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> -->
                </div>
                <div class="myBoxHeaderTableRight">
                    <!-- <p>{{this.$store.state.tagName.paritemName || '常规检查'}}</p> -->
                    <p>{{moment(this.$store.state.tagName.checkDate).format('YYYY-MM-DD')}}</p>
                </div>
            </div>
        </div>
        <div class="defaultBoxDelow">
            <button class="defaultBoxDelowButton" @click="download">
                <van-icon name="down" />
                下载报告
            </button>
        </div>
        <div class="defaultBoxTable">
            <div class="defaultBoxTableItem">
                <div class="defaultBoxTableItemL1">
                    检查项
                </div>
                <div class="defaultBoxTableItemL2">
                    结果
                </div>
                <div class="defaultBoxTableItemL3" style="width: 100px;">
                    状态值
                </div>
                <div class="defaultBoxTableItemL3" style="width: 100px;">
                    单位
                </div>
                <div class="defaultBoxTableItemL3">
                    参考值
                </div>
                
            </div>
            <div class="defaultBoxTableItem" 
                v-for="(item,index) in tableList" :key="index" 
                :class="item.reportValueAll== '阳性' || item.reportValueAll == '+' ? 'defaultBoxTableItemActive' : ''"
                >
                <div class="defaultBoxTableItemL1">
                    <span v-show="item.testItemName">
                        {{item.testItemName}}
                    </span>
                </div>
                <div class="defaultBoxTableItemL2">
                    <span v-show="item.reportValueAll">
                            
                        <span  :class="item.reportValueAll == '阳性' || item.reportValueAll == '+' ? 'item' : 'itemActive'">
                           {{item.reportValueAll}}
                           
                        </span>
                    </span>
                    
                </div>
                <div class="defaultBoxTableItemL3" style="width: 100px;">
                    {{item.resultStatus}}
                </div>
                <div class="defaultBoxTableItemL3" style="width: 100px;">
                    {{item.unit}}
                </div>
                <div class="defaultBoxTableItemL3">
                    <!-- <span v-show="item.refRange">
                        {{item.refRange}}
                    </span>   -->
                    <p v-for="(items,indexs) in item.refRange" :key="indexs">{{items}}
                        <span v-if="indexs != item.refRange.length - 1">;</span>
                    </p> 
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import { queryReportItem, downloadReport , info } from '../https/api'
import moment from 'moment'
export default {
    data(){
        return {
            tableList: [],
            downLoadToken: null,
            userinfoData: {
                patient: {
                    cName: ''
                }
            }
        }
    },
    created(){
        if(this.$route.query.SectionNo){
            this.getQueryReportItem()
        }else{
            this.$router.back()
        }
        this.getUserInfo()

        document.title =  this.$store.state.tagName.paritemName || '检验报告详情'// moment(this.$route.query.ReceiveDate).format('YYYY-MM-DD') + '检验项目'
    },
    methods:{
        moment,
        getQueryReportItem(){
            this.$toast.loading('数据请求中...')
            queryReportItem({
                ...this.$route.query 
            })
            .then(res => {
                this.$toast.clear()
           
                if(res.data.statusCode == 200){
                    this.tableList = res.data.data
                    for(let i =0; i < this.tableList.length; i++){
                        if(this.tableList[i].refRange.includes(';')){
                            this.tableList[i].refRange = this.tableList[i].refRange.split(';')
                        }else{
                            this.tableList[i].refRange = [this.tableList[i].refRange]
                        }
                        
                        console.log(this.tableList[i].refRange)
                    }
                }
            })
        },
        download(){
            downloadReport({
                ...this.$route.query 
            })
            .then(res => {
                if(res.data.statusCode == 200){
                    console.log(res.data.data)
                    this.downLoadToken = res.data.data
                    window.location.href = location.protocol + '//' + location.host + '/report/show?token=' + res.data.data
                }
            })
        },
        getUserInfo(){
            info()
            .then(res =>{
                
                if(res.data.statusCode == 200){
                    // console.log(res.data.data)
                    this.userinfoData = res.data.data

                    console.log(this.userinfoData)
                }
            })
        },
        goHome(){
            console.log('返回')
            this.$router.back()
        }
    }
}
</script>

<style scoped>
.defaultBox{
    width: 100%;
    height: 100%;
    background: #EDEDED;
    margin-bottom: 50px;
    box-sizing: border-box;
    font-size: 26px;
}
.myBoxHeader{
    width: 100%;
	height: 220px;
	background: url('../assets/name.jpg') no-repeat;
	background-size: 100% 100%;
	position: relative;
    padding: 0 20px;
    box-sizing: border-box;
}
.myBoxHeaderTitle{
    width: 100%;
    height: 70px;
    font-size: 30px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    /* background: tan; */
    padding-top: 20px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
    
}
.myBoxHeaderTitleName{
    width: 100%;
    height: 50px;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.myBoxHeaderTable{
    width: 100%;
    height: 50px;
    /* background: yellowgreen; */
    display: flex;
    font-size: 28px;
}
.myBoxHeaderTableLeft{
    width: 50%;
    height: 100%;
    border-right: 1px solid #fff;
    padding: 0 20px;
    box-sizing: border-box;
}
.myBoxHeaderTableLeft p{
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0;
    color: #fff;
    line-height: 50px;
    text-align: right;
}
.myBoxHeaderTableRight{
    width: 50%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}
.myBoxHeaderTableRight p{
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0;
    color: #fff;
    line-height: 50px;
    text-align: left;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.defaultBoxDelow{
    width: 100%;
    height: 100px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
}
.defaultBoxDelowButton{
    width: 200px;
    height: 60px;
    background: #2884FF;
    color: #fff;
    border: none;
    border-radius: 10px;
    outline: none;
}

.defaultBoxTable{
    width: 100%;
    height: auto;
    background: #fff;
}
.defaultBoxTableItem{
    display: flex;
    justify-content: space-around;
    padding: 20px 20px;
    box-sizing: border-box;
    line-height: 30px;
}

.defaultBoxTableItemActive{
    background: #F5E6C9;
}

.defaultBoxTableItemL1{
    width: calc(100% - 360px);
    /* background: #2884FF; */
    text-align: left;
}
.defaultBoxTableItemL2{
    width: 220px;
    /* background: yellow; */
    text-align: center;
}
.defaultBoxTableItemL3{
    width: 220px;
    /* background: navajowhite; */
    text-align: center;
}
.defaultBoxTableItemL3 p{
    width: 100%;
    margin: 0;
    padding: 0;
}

.item{
    color: #F6251F;
    margin-left: 2px;
}
.itemActive{
    
    margin-left: 2px;
}

.myBoxHeaderTitleIcon{
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 30px;
    color: #fff;
    z-index: 999;
}
</style>