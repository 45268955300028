<template>
    <div class="myBox">
        <div class="myBoxHeader">
            <img class="myBoxHeaderOut" @click="goOut" src="../assets/out.png" />
            <div class="myBoxHeaderTitle">
                <van-icon class="myBoxHeaderTitleIcon" @click="goHome" size='0.7rem' name="arrow-left" />
                检查报告
            </div>
            <div class="myBoxHeaderUser">
                <div class="myBoxHeaderUserImage">
                     <img  src="../assets/user.png" alt="">
                </div>
                <div class="myBoxHeaderUserName">
                    姓名:
                    <span v-if="userinfoData.patient">
                        {{userinfoData.patient.cName}}
                    </span>
                    
                </div>
                <span class="myBoxHeaderUserCode">
                    卡号: 
                    <span >
                        {{userinfoData.phone}}
                    </span>
                </span>
            </div>
        </div>
        <div class="myBoxMain">
            <p class="myBoxMainTitle">只能查询30天内的报告</p>
            <div class="homeMainTime" style="margin-bottom: 20px;">
				<div class="homeMainTimeItem">
					<div class="homeMainTimeItemLeft" style="width:50%">开始日期</div>
					<div class="homeMainTimeItemRight" style="width:50%" @click="goStart">
						<span>{{startTime}}</span>
						<van-icon name="arrow" />
					</div>
				</div>
			</div>
            <div class="myBoxMainItem" v-for="(item,index) in arrData" :key="index">
                <div class="myBoxMainItemTitle">
                    检验报告: {{moment(item.time).format('YYYY-MM-DD')}}
                </div>
                <div class="homeMainTime">
                    <div class="homeMainTimeItem" v-for="(items,indexs) in item.children" :key="indexs">
                        <div class="homeMainTimeItemLeft"  @click="goDefault(items)">{{items.paritemName}}</div>
                        <div class="homeMainTimeItemRight" @click="goDefault(items)">
                            <span></span>
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="myBoxMainItemEmty" v-if="arrData.length == 0">
                <img src="../assets/empty.png" />
                <p>暂未查询到{{startTime}}日到今天的报告<br />
                    请选择日期重新查询</p>
            </div>
        </div>
        <!-- 选择日期 -->
		<van-calendar title="只能查询30天内的报告" v-model="isShowTop" confirm-text='立即查询'  :default-date="startTimeString"  @confirm="onConfirm"  :min-date="minDate" :max-date="maxDate"  />
    </div>
</template>

<script>
import moment from 'moment'
import { queryReport, info } from '../https/api'
export default {
    data(){
        return {
           arrData:[],
           userinfoData: {
               patient:{
                   cName: '',
                   patNo: ''
               },
               phone: ''
           },
           startTime: moment(new Date().getTime() - (86400000 * (this.$route.query.timeNumber - 1)) ).format('YYYY-MM-DD'),
           minDate: new Date(new Date().getTime() - (86400000 * 29)),
		   maxDate: new Date(),
           isShowTop: false,
           startTimeString: new Date()
        }
    },
    created(){
        if(this.$route.query.timeNumber){
            // this.getQueryReport(this.$route.query.timeNumber)
        }else{
            this.$router.back()
        }
        this.getUserInfo()
        document.title = '检验项目'
    },
    methods: {
        moment,
        goStart(){
            this.isShowTop = true
            this.startTimeString = new Date(this.startTime)

            console.log('测试')
        },
        onConfirm(date) {
			this.isShowTop = false;
			this.startTime = moment(date).format('YYYY-MM-DD')
			// this.date = this.formatDate(date);

            let endTimes = new Date().getTime()
			let startTimes = new Date(this.startTime).getTime()

			let timeNumber = parseInt((endTimes - startTimes)/86400000) || 0

            this.$router.replace('/my?timeNumber=' + (timeNumber + 1))
		},
        getQueryReport(index){
            this.$toast.loading('数据请求中...')
            queryReport({
                DayCount: index
            })
            .then(result =>{
                this.$toast.clear()
                if(result.data.statusCode == 200){
                    console.log(result.data.data)
                    let data = result.data.data
                    let arr = []
                    for(let i=0; i<data.length; i++){
                        if(!arr.includes(data[i].checkDate)){
                            arr.push(data[i].checkDate)
                        }
                    }
                    
                    let arrData = []
                    for(let i=0; i<arr.length; i++){
                        let jsx = {
                            time: arr[i],
                            children: []
                        }
                        for(let k=0; k<data.length; k++){
                            if(arr[i] == data[k].checkDate){
                                jsx.children.push(data[k])
                            }
                        }
                        arrData.push(jsx)
                    }

                    console.log(arrData)
                    this.arrData = arrData
                }else{
                    this.$toast.fail(result.data.errors.DayCount.join(','));
                }
            })
            .catch(res =>{
                // console.log('请求失败')
                // this.$toast.fail('');
                // this.$router.replace('/')
            })
            
        },
        goDefault(item){
            console.log(item)
            this.$store.commit('setTagName',item)
            this.$router.push({
                path: '/default',
                query:{
                    SectionNo: item.sectionNo,
                    TestTypeNo: item.testTypeNo,
                    SampleNo: item.sampleNo,
                    ReceiveDate: item.receiveDate
                }
            })
        },
        getUserInfo(){
            info()
            .then(res =>{
                
                if(res.data.statusCode == 200){
                    // console.log(res.data.data)
                    this.userinfoData = res.data.data || {
                        patient:{
                            cName: '',
                            patNo: ''
                        },
                        phone: ''
                    }

                    console.log(this.userinfoData)
                }
            })
        },
        goHome(){
            // this.$router.replace('/')
            this.$router.back()
        },
        goOut(){
            this.$store.commit('setAccessToken',  null)
			this.$store.commit('setRefreshToken',  null)
            this.$router.replace('/')
        }
    },
    computed:{
        timeNumber(){
            return this.$route.query.timeNumber
        }
    },
    watch:{
        timeNumber:{
            handler(news){
                console.log(news)
                if(news){
                    this.getQueryReport(news)
                }
                
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>
.myBox{
    width: 100%;
    height: 100%;
    background: #EDEDED;
}
.myBoxHeader{
    width: 100%;
	height: 220px;
	background: url('../assets/name.jpg') no-repeat;
	background-size: 100% 100%;
	position: relative;
    padding: 0 20px;
    box-sizing: border-box;
}
.myBoxHeaderOut{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 30px;
    top: 15px;
    z-index: 999;
}
.myBoxHeaderTitle{
    width: 100%;
    height: 70px;
    font-size: 30px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    /* background: tan; */
    padding-top: 20px;
    box-sizing: border-box;
    position: relative;
}
.myBoxHeaderTitleIcon{
    position: absolute;
    left: 0;
    top: 20px;
    font-size: 30px;
}
.myBoxHeaderUser{
    width: 100%;
    height: 130px;
    background: url('../assets/name.png') no-repeat;
    background-size: 100% 100%;
    padding: 15px 25px;
    box-sizing: border-box;
    display: flex;
    position: relative;
}
.myBoxHeaderUserImage{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    overflow: hidden;
}
.myBoxHeaderUserImage img{
    width: 100%;
    height: 100%;
}

.myBoxHeaderUserName{
    width: 450px;
    height: 100px;
    /* background: yellowgreen; */
    padding-left: 30px;
    font-size: 30px;
    line-height: 100px;
    color: #1159C8;
    font-weight: bold;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.myBoxHeaderUserCode{
    position: absolute;
    width: 230px;
    height: 60px;
    background: #fff;
    color: #1159C8;
    text-align: center;
    line-height: 60px;
    border-radius: 10px;
    right: 20px;
    top: 35px;
    font-size: 20px;
}

.myBoxMain{
    width: 100%;
    height: auto;
    padding: 20px;
    box-sizing: border-box;
}
.myBoxMainItem{
    margin-bottom: 20px;
}
.myBoxMainItemTitle{
    color: #1159C8;
    font-size: 28px;
    margin-bottom: 15px;
    padding-left: 15px;
    box-sizing: border-box;
}
.homeMainTime{
	width: 100%;
	height: auto;
	background: #fff;
	border-radius: 15px;
	padding: 10px 20px;
	box-sizing: border-box;
}
.homeMainTimeItem{
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	box-sizing: border-box;
	line-height: 80px;
	font-size: 30px;
}
.homeMainTimeItemLeft{
	width: 90%;
	height: 100%;
	color: #4D4D4D;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.homeMainTimeItemRight{
	width: 10%;
	height: 100%;
	color: #B9B9B9;
	text-align: right;
}
.homeMainTimeItemRight span{
	color: #3893FE;
	margin-right: 15px;
}

.myBoxMainItemEmty{
    width: 100%;
    height: auto;
    text-align: center;
    color: #666;
    font-size: 30px;
    padding-top: 100px;
    box-sizing: border-box;
}
.myBoxMainItemEmty img{
    width: 300px;
    margin-bottom: 10px;
}
.myBoxMainItemEmty p{
    color: #5B585B;
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.myBoxMainTitle{
    width: 100%;
    height: 35px;
    font-size: 20px;
    line-height: 35px;
}
</style>